import {
  Button,
  DataGrid,
  ScrollView,
  Popup,
  Form,
  SelectBox,
  NumberBox,
  TextBox,
} from "devextreme-react";
import {
  Column,
  ColumnChooser,
  Export,
  FilterRow,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import React, { useEffect, useState } from "react";
import {
  articulos,
  empresa,
  inventario as initialInventario,
  tipo,
} from "./data_test";
import { GroupItem, Item, Label } from "devextreme-react/form";

import * as DinamicQueries from "../../api/DinamicsQuery";
import * as ReportsService from "../../api/reports";

const InventarioPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [dataForm, setDataForm] = useState({
    tipo_movimiento: "",
    numero_orden: "",
    no_factura: "",
    empresa: "",
    region: "",
    cuenta_dotar: "",
    responsable: "",
    tipo_articulo: "",
    articulo: "",
    detalle_orden: [{ monto_unitario: 0, cantidad: 0, monto_total: 0 }],
  });
  const [regiones, setRegiones] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [inventario, setInventario] = useState(initialInventario);

  useEffect(() => {
    // Obtener regiones
    ReportsService.getInventario()
      .then((resp) => {
        console.log("🚀 ~ ReportsService.getInventario ~ resp:", resp.data);
        setInventario(resp.data.data);
      })
      .catch((error) => console.error("Error en getInventario:", error));
    ReportsService.getRegion()
      .then((resp) => {
        console.log("🚀 ~ ReportsService.getRegion ~ resp:", resp.data);
        setRegiones(resp.data);
      })
      .catch((error) => console.error("Error en getRegion:", error));
    ReportsService.getUsers()
      .then((resp) => {
        console.log("🚀 ~ ReportsService.getUsers ~ resp:", resp.data);
        setUsuarios(resp.data);
      })
      .catch((error) => console.error("Error en getUsers:", error));

    ReportsService.getClients()
      .then((resp) => {
        console.log("🚀 ~ ReportsService.getClients ~ resp:", resp.data);
        setClientes(resp.data);
      })
      .catch((error) => console.error("Error en getClients:", error));
  }, []);

  useEffect(() => {
    const detalle_orden = dataForm.detalle_orden[0];
    const monto_total = detalle_orden.monto_unitario * detalle_orden.cantidad;
    setDataForm((prevData) => ({
      ...prevData,
      detalle_orden: [{ ...detalle_orden, monto_total }],
    }));
  }, [
    dataForm.detalle_orden[0].monto_unitario,
    dataForm.detalle_orden[0].cantidad,
  ]);

  const options = [{ id: 1, name: "Entrada", value: "entrada" }];

  const openPopup = () => {
    resetForm();
    setShowPopup(true);
  };

  const resetForm = () => {
    setDataForm({
      tipo_movimiento: "",
      numero_orden: "",
      no_factura: "",
      empresa: "",
      region: "",
      cuenta_dotar: "",

      responsable: "",
      tipo_articulo: "",
      articulo: "",
      detalle_orden: [{ monto_unitario: 0, cantidad: 0, monto_total: 0 }],
      cedula_empleado: "",
      cedula_responsable: "",
    });
  };
  const hidePopup = () => {
    resetForm(); // Primero resetea el formulario
    setShowPopup(false); // Luego cierra el popup
  };

  const handleSubmit = () => {
    const newMovimiento = { ...dataForm, id_movimiento: inventario.length + 1 };

    if (newMovimiento.tipo_movimiento === "salida") {
      newMovimiento.detalle_orden[0].cantidad = -Math.abs(
        newMovimiento.detalle_orden[0].cantidad
      );
    }

    ReportsService.saveInventario(newMovimiento)
      .then((response) => {
        console.log("🚀 ~ Inventario guardado ~ response:", response.data);

        // Volver a obtener la lista de inventario desde el backend
        return ReportsService.getInventario();
      })
      .then((resp) => {
        console.log("🚀 ~ Inventario actualizado:", resp.data.data);
        setInventario(resp.data.data); // Ahora sí se actualiza la tabla
        resetForm();
        hidePopup();
      })
      .catch((error) => {
        console.error("Error al guardar inventario:", error);
      });
  };

  const renderContent = () => {
    return (
      <ScrollView height="100%" width="100%">
        <Form className="form-container" style={{ height: "100%" }}>
          <GroupItem colCount={3} style={{ height: "100%" }}>
            <Item>
              <Label text="Tipo de Movimiento" />
              <SelectBox
                dataSource={options}
                valueExpr="value"
                displayExpr="name"
                value={dataForm.tipo_movimiento}
                onValueChanged={(e) =>
                  setDataForm({ ...dataForm, tipo_movimiento: e.value })
                }
              />
            </Item>
            <Item>
              <Label>Número de Orden</Label>
              <NumberBox
                showClearButton={true}
                value={dataForm.numero_orden}
                onValueChanged={(e) =>
                  setDataForm({ ...dataForm, numero_orden: e.value })
                }
              />
            </Item>
            <Item>
              <Label>Número de Factura</Label>
              <NumberBox
                showClearButton={true}
                value={dataForm.no_factura}
                onValueChanged={(e) =>
                  setDataForm({ ...dataForm, no_factura: e.value })
                }
              />
            </Item>
          </GroupItem>
          <GroupItem colCount={3}>
            <Item>
              <Label text="Empresa" />
              <SelectBox
                dataSource={empresa}
                valueExpr="value"
                displayExpr="name"
                value={dataForm.empresa}
                onValueChanged={(e) =>
                  setDataForm({ ...dataForm, empresa: e.value })
                }
              />
            </Item>
            <Item>
              <Label text="Región" />
              <SelectBox
                dataSource={regiones}
                valueExpr="nombre_region" // Ahora se usa el nombre como valor
                displayExpr="nombre_region" // Se sigue mostrando el nombre
                value={dataForm.region}
                onValueChanged={
                  (e) => setDataForm({ ...dataForm, region: e.value }) // Guarda el nombre de la región
                }
              />
            </Item>

            <Item>
              <Label text="Cuenta a dotar" />
              <SelectBox
                dataSource={clientes}
                valueExpr="nombre_cliente" // Ahora se usa el nombre como valor
                displayExpr="nombre_cliente"
                value={dataForm.cuenta_dotar}
                onValueChanged={
                  (e) => setDataForm({ ...dataForm, cuenta_dotar: e.value }) // Guarda el nombre en vez del código
                }
              />
            </Item>
          </GroupItem>
          <GroupItem colCount={2}>
            {/* Responsable */}
            <Item>
              <Label text="Responsable" />
              <SelectBox
                dataSource={usuarios.filter(
                  (user) =>
                    user.perfil === "Coordinador" || user.perfil === "Gerente"
                )}
                valueExpr="nombre" // Ahora el valor que guarda es el nombre, no el ID
                displayExpr="nombre" // Se sigue mostrando el nombre
                value={dataForm.responsable} // Ahora almacena y selecciona por nombre
                onValueChanged={(e) => {
                  const usuarioSeleccionado = usuarios.find(
                    (user) => user.nombre === e.value
                  );
                  setDataForm({
                    ...dataForm,
                    responsable: e.value, // Guarda el nombre del responsable
                    cedula_responsable: usuarioSeleccionado?.cedula || "", // Guarda la cédula asociada
                  });
                }}
              />
            </Item>

            <Item>
              <Label text="Cédula Responsable" />
              <TextBox value={dataForm.cedula_responsable} readOnly={true} />
            </Item>
          </GroupItem>

          <GroupItem colCount={2}>
            <Item>
              <Label text="Tipo de artículo" />
              <SelectBox
                dataSource={tipo}
                valueExpr="value"
                displayExpr="name"
                value={dataForm.tipo_articulo}
                onValueChanged={(e) =>
                  setDataForm({ ...dataForm, tipo_articulo: e.value })
                }
              />
            </Item>
            <Item>
              <Label text="Artículos" />
              <SelectBox
                dataSource={articulos}
                valueExpr="value"
                displayExpr="name"
                value={dataForm.articulo}
                onValueChanged={(e) =>
                  setDataForm({ ...dataForm, articulo: e.value })
                }
              />
            </Item>
          </GroupItem>
          <GroupItem colCount={3}>
            <Item>
              <Label>Monto Unitario</Label>
              <NumberBox
                showClearButton={true}
                value={dataForm.detalle_orden[0].monto_unitario} // Se asigna el valor actual
                onValueChanged={(e) =>
                  setDataForm({
                    ...dataForm,
                    detalle_orden: [
                      {
                        ...dataForm.detalle_orden[0],
                        monto_unitario: e.value || 0,
                      },
                    ],
                  })
                }
              />
            </Item>

            <Item>
              <Label>Cantidad</Label>
              <NumberBox
                showClearButton={true}
                value={dataForm.detalle_orden[0].cantidad} // Se asigna el valor actual
                onValueChanged={(e) =>
                  setDataForm({
                    ...dataForm,
                    detalle_orden: [
                      { ...dataForm.detalle_orden[0], cantidad: e.value || 0 },
                    ],
                  })
                }
              />
            </Item>
            <Item>
              <Label>Total</Label>
              <NumberBox
                showClearButton={true}
                readOnly={true}
                value={dataForm.detalle_orden[0].monto_total}
              />
            </Item>
          </GroupItem>
        </Form>
      </ScrollView>
    );
  };

  return (
    <div>
      <div className="header-grid-right">
        <Button className="btn-agregar mt-3" onClick={openPopup}>
          Añadir Movimiento
        </Button>
      </div>
      <DataGrid
        className="tabla-Cliente"
        dataSource={inventario} // Evita errores si inventario es null o undefined
        showBorders={true}
        remoteOperations={true}
        focusedRowEnabled={true}
        style={{ height: "430px", width: "100%" }}
        keyExpr="id"
        wordWrapEnabled={true}
      >
        <Export enabled={true} />
        <ColumnChooser enabled={true} mode="select" />
        <FilterRow visible={true} />
        <Paging defaultPageSize={12} />
        <HeaderFilter visible={true} />
        <Column
          width={120}
          caption="Tipo de Movimiento"
          dataField="tipo_movimiento"
          dataType="string"
        />
        <Column
          width={120}
          caption="Número de Orden"
          dataField="numero_orden"
          dataType="number"
          cellRender={(data) => Number(data.value) || 0}
        />
        <Column
          width={120}
          caption="Número de Factura"
          dataField="no_factura"
          dataType="number"
          cellRender={(data) => Number(data.value) || 0}
        />

        <Column
          width={200}
          caption="Detalle Orden"
          dataField="detalle_orden"
          cellRender={(data) =>
            data.value
              ? data.value.map((item, index) => (
                  <div key={index}>
                    Cantidad: {item.cantidad}, Total: ${item.monto_total}
                  </div>
                ))
              : "Sin detalles"
          }
        />

        <Column
          width={200}
          caption="Empresa"
          dataField="empresa"
          dataType="string"
        />
        <Column
          width={140}
          caption="Región"
          dataField="region"
          dataType="string"
        />
        <Column
          width={200}
          caption="Cuenta a dotar"
          dataField="cuenta_dotar"
          dataType="string"
        />
        <Column
          width={150}
          caption="Responsable"
          dataField="responsable"
          dataType="string"
        />
        <Column
          width={150}
          caption="Cedula Empleado"
          dataField="cedula_responsable"
          dataType="number"
        />
        <Column
          width={150}
          caption="Tipo Artículo"
          dataField="tipo_articulo"
          dataType="string"
        />
        <Column
          width={150}
          caption="Artículo"
          dataField="articulo"
          dataType="string"
        />
        <Column
          width={150}
          caption="Fecha Creación"
          dataField="fecha_creacion"
          cellRender={(data) =>
            data.value
              ? new Date(data.value._seconds * 1000).toLocaleDateString()
              : ""
          }
        />
      </DataGrid>

      <Popup
        width={"80%"}
        height={"85%"}
        visible={showPopup}
        onHiding={hidePopup}
        showCloseButton={true}
        contentRender={renderContent}
        toolbarItems={[
          {
            widget: "dxButton",
            toolbar: "bottom",
            location: "after",
            options: {
              text: "Guardar",
              onClick: handleSubmit,
            },
          },
          {
            widget: "dxButton",
            toolbar: "bottom",
            location: "after",
            options: {
              text: "Cancelar",
              onClick: hidePopup,
            },
          },
        ]}
      />
    </div>
  );
};

export default InventarioPage;
