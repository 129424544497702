export const inventario = [
  {
    id_movimiento: 1,
    tipo_orden: "",
    numero_orden: "",
    no_factura: "",
    empresa: "CONSORCIO PROMOTING C.A.",
    region: "CAPITAL",
    fecha_creacion: "",
    cuenta_dotar: "",
    empleado_interno: "",
    cliente_op: "",
    orden_op: "",
    plan: "",
    responsable: "",
    detalle_orden: [
      {
        articulo: "",
        tipo: "",
        monto_unitario: 0,
        cantidad: 5,
        monto_total: 0,
        disponible: 0,
      },
    ],
    observaciones: "",
  },
];

export const tipo = [
  { id: 1, name: "Uniformes", value: "uniformes" },
  { id: 2, name: "Otros", value: "others" },
];

export const articulos = [
  { id: 1, name: "Camisa Dama XS", value: "camisa dama xs" },
  { id: 2, name: "Camisa Dama S", value: "camisa dama s" },
  { id: 3, name: "Camisa Dama M", value: "camisa dama m" },
  { id: 4, name: "Camisa Dama L", value: "camisa dama l" },
  { id: 5, name: "Camisa Dama XL", value: "camisa dama xl" },
  { id: 6, name: "Camisa Caballero XS", value: "camisa caballero xs" },
  { id: 7, name: "Camisa Caballero S", value: "camisa caballero s" },
  { id: 8, name: "Camisa Caballero M", value: "camisa caballero m" },
  { id: 9, name: "Camisa Caballero L", value: "camisa caballero l" },
  { id: 10, name: "Camisa Caballero XL", value: "camisa caballero xl" },
  { id: 11, name: "Gorro", value: "gorro" },
  { id: 12, name: "Guantes", value: "guantes" },
  { id: 13, name: "Tapa Boca", value: "tapa boca" },
  { id: 14, name: "Boligrafo", value: "boligrafo" },
  { id: 15, name: "Lanilla", value: "lanilla" },
  { id: 16, name: "Tirro", value: "tirro" },
  { id: 17, name: "Botas", value: "botas" },
];

export const empresa = [
  { id: 1, name: "CONSORCIO PROMOTING C.A.", value: "promoting" },
  { id: 2, name: "WORKFORCE", value: "workforce" },
  { id: 3, name: "TRADE BRANDING", value: "trade branding" },
];
